<template>
  <div class="search">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="onLoad"
    >
      <van-sticky>
        <search-top @changeOrder="changeOrder" :order="order"></search-top>
      </van-sticky>
      <lists :productList="productList" ref="search"></lists>
    </van-list>
  </div>
</template>
<script>
import Lists from "@main/common/Lists";
import SearchTop from "./components/SearchTop";

import Vue from "vue";
import { Sticky, List, Image } from "vant";
Vue.use(List).use(Sticky).use(Image);
export default {
  name: "Search",
  components: {
    Lists,
    SearchTop,
  },
  data() {
    return {
      productList: [],
      sreachValues: "", //搜索关键词
      pageIndex: 1,
      order: "", //排序
      //isNoData: false,
      //isfirst: true,
      loading: true,
      finished: false,
      isCouponProduct: false,
    };
  },
  created() {
    const { SreachValues, Order, title, IsCouponProduct } = this.$route.query;
    this.sreachValues = SreachValues;
    this.order = Order;
    this.isCouponProduct = IsCouponProduct;
    this.getSearchProductByPage();
    title && (document.title = title);
  },
  mounted() {
    window.document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        document.getElementsByClassName("search-top-row")[0].style.paddingTop =
          "calc(15px + env(safe-area-inset-top))";
      } else {
        document.getElementsByClassName("search-top-row")[0].style.paddingTop =
          "15px";
      }
    });
  },
  methods: {
    // 获取搜索页数据
    getSearchProductByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      if (this.order !== 1 && this.order !== 2) {
        param = {
          SreachValues: this.sreachValues,
          PageIndex: this.pageIndex,
          IsCouponProduct: this.isCouponProduct,
        };
      } else {
        param = {
          SreachValues: this.sreachValues,
          Order: this.order,
          PageIndex: this.pageIndex,
          IsCouponProduct: this.isCouponProduct,
        };
      }
      this.$api.search
        .loadSearchProductByPage(param)
        .then((res) => {
          console.log(res);
          const data = res.data;
          this.productList = data;
          //this.isfirst = false;
          if (
            this.productList === null ||
            this.productList === "" ||
            this.productList.length === 0
          ) {
            this.isPlaceholder = true;
          } else {
            this.isPlaceholder = false;
          }
          this.loading = false;
          console.log(this.isPlaceholder);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.isfirst = false;
          //console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      //console.log("这是isfirst" + this.isfirst);
    },
    // 筛选
    changeOrder(type) {
      this.$refs.search.scrollTop = 0;
      console.log(this.$refs.search.scrollTop);
      this.order = type;
      //if (type === !this.order) return;
      this.pageIndex = 1;
      //this.isNoData = false;
      this.loading = true;
      this.finished = false;
      this.getSearchProductByPage();
    },
    //加载更多
    onLoad() {
      console.log("执行加载更多");
      // if (this.isNoData) {
      //   this.loading = false;
      //   this.finished = true;
      //   return;
      // }
      // this.loading = false;
      // this.finished = false;
      // if (this.isfirst) return;
      // console.log("通过");
      // this.loading = true;
      // this.finished = false;
      let param = {};
      if (this.order !== 1 && this.order !== 2) {
        param = {
          SreachValues: this.sreachValues,
          PageIndex: this.pageIndex + 1,
          IsCouponProduct: this.isCouponProduct,
        };
      } else {
        param = {
          SreachValues: this.sreachValues,
          Order: this.order,
          PageIndex: this.pageIndex + 1,
          IsCouponProduct: this.isCouponProduct,
        };
      }
      this.$api.search
        .loadSearchProductByPage(param)
        .then((res) => {
          //console.log(res.data);
          const data = res.data;
          console.log(data);
          if (data.length === 0) {
            this.loading = false;
            this.finished = true;
            //this.isNoData = true;
          } else {
            this.loading = false;
            this.productList = this.productList.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch((error) => {
          //console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
      //console.log("这是isfirst" + this.isfirst);
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  width: 100%;
  background-color: #f6f6f6;
}
</style>
